import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'//引入axios
import './assets/style.scss';
import config from './config';

import { Dialog } from 'vant';
import 'vant/lib/index.css';

Vue.prototype.$config = config;
Vue.prototype.API = config.API;
Vue.prototype.ADMIN = config.ADMIN;
Vue.prototype.Dialog = Dialog;

const token = localStorage.getItem('token')
console.log('token', token);
store.commit('saveToken', token)

// ================ axios 配置 ================
axios.defaults.baseURL = config.API;
axios.defaults.rejectUnauthorized = false;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.interceptors.request.use(function (config) {
  config.headers.token = store.state.token;
  // if(store.state.token) config.headers.token = store.state.token;
  return config;
}, function (error) {
  console.error(error, 2222222)
});

axios.interceptors.response.use(function (response) {
  if (response.status == 200) {
    // 判断返回数据的状态 以便notification使用
    if (Object.prototype.toString.call(response.data) == '[object Object]') {
      if (!response.data.status) response.data.status = 1;
      switch (response.data.status) {
        case -100:
          response.data.s = 'error';
          console.error('没有权限')
          router.push('/login/'+store.state.shop_id)
          break;
        case -1:
          response.data.s = 'error';
          break;
        case 0:
          response.data.s = 'warning';
          break;
        case 1:
          response.data.s = 'success';
          break;
        case 2:
          response.data.s = 'info';
          break;
        default:
          response.data.s = 'success';
          break;
      }
    };
    return response.data
  }
  else {
    return response;
  }
}, function (error) {
  console.error(error, 333444444)
});

Vue.axios = Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')