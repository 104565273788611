import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index0',
    component: () => import('../views/noshop.vue')
  },
  {
    path: '/:shop_id',
    name: 'Index',
    meta: {
      tab: 1
    },
    component: () => import('../views/index.vue')
  },
  {
    path: '/goods/:shop_id/:goods_id',
    name: 'Goods',
    meta: {
      tab: 1,
      hideFooter: true
    },
    component: () => import('../views/goods.vue')
  },
  {
    path: '/auction/:shop_id',
    name: 'auction',
    meta: {
      tab: 1
    },
    component: () => import('../views/auction.vue')
  },
  {
    path: '/user/:shop_id',
    name: 'User',
    meta: {
      tab: 3
    },
    component: () => import('../views/user.vue')
  },
  {
    path: '/shopInfo/:shop_id',
    name: 'shopInfo',
    meta: {
      tab: 3
    },
    component: () => import('../views/shopInfo.vue')
  },
  {
    path: '/article/:shop_id',
    name: 'article',
    meta: {
      tab: 3
    },
    component: () => import('../views/article.vue')
  },
  {
    path: '/fav/:shop_id',
    name: 'fav',
    meta: {
      tab: 3
    },
    component: () => import('../views/fav.vue')
  },
  {
    path: '/login/:shop_id',
    name: 'login',
    meta: {
      hideFooter: true
    },
    component: () => import('../views/login.vue')
  },
  {
    path: '/kefu/:shop_id',
    name: 'kefu',
    meta: {
      tab: 3
    },
    component: () => import('../views/kefu.vue')
  },
  {
    path: '/noshop',
    name: 'noshop',
    meta: {
      tab: 1
    },
    component: () => import('../views/noshop.vue')
  },
  {
    path: '/wechat/:shop_id',
    name: 'wechat',
    meta: {
      tab: 3
    },
    component: () => import('../views/wechat.vue')
  },
  {
    path: '/service/desc/:shop_id',
    name: 'Service',
    meta: {
      tab: 2
    },
    component: () => import('../views/service/index.vue')
  },
  {
    path: '/service/form/:shop_id',
    name: 'ServiceForm',
    meta: {
      tab: 2,
      hideFooter: true
    },
    component: () => import('../views/service/form.vue')
  },
  {
    path: '/service/:shop_id',
    name: 'ServiceList',
    meta: {
      tab: 2,
    },
    component: () => import('../views/service/list.vue')
  },
  {
    path: '/service/item/:shop_id',
    name: 'ServiceItem',
    meta: {
      tab: 2,
    },
    component: () => import('../views/service/item.vue')
  },

  {
    path: '/serviceList/:shop_id',
    name: 'serviceStatusList',
    meta: {
      tab: 2,
    },
    component: () => import('../views/serviceList.vue')
  },

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

// 避免跳转到当前网址的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// router.beforeEach((to, from, next) => {
//   if(to.name == 'Service'){
//     window.location.href = 'https://mp.weixin.qq.com/s/Qd44GFyaNH05XV0JdX_nVw'
//   }
//   else{
//     next()
//   }
// })

router.afterEach((to, from) => {
  if (to.params.shop_id) {
    const shop_id = to.params.shop_id;
    store.commit('saveShopId', shop_id)
  }
})


export default router
